import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    component: () =>
      import(/* webpackChunkName: "baseLayout" */ "@/layouts/BaseLayout.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
        meta: {
          requiresAuth: false,
          title: "Home"
        }
      },
      {
        path: "/login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/auth/LoginView.vue"),
        meta: {
          notLogged: true,
          requiresAuth: false,
          title: "Iniciar Sesión"
        }
      },
      {
        path: "/signup",
        name: "signup",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/auth/RegisterView.vue"
          ),
        meta: {
          notLogged: true,
          requiresAuth: false,
          title: "Registro"
        }
      },
      {
        path: "/recuperar",
        name: "recuperar",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/auth/RecoverPassView.vue"
          ),
        meta: {
          notLogged: true,
          requiresAuth: false,
          title: "Recuperar"
        }
      },
      {
        path: "/vecinos",
        name: "ApplicationsList",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Dashboard/ApplicationsListView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Solicitudes"
        }
      },
      {
        path: "/validar",
        name: "Applications",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/components/Dashboard/Applications.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Validar"
        }
      },
      {
        path: "/solicitud/editar",
        name: "ApplicationEdit",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/components/Dashboard/Applications.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Editar"
        }
      },
      {
        path: "detalles/:id",
        name: "ApplicationDetails",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/components/Dashboard/ApplicationDetail.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Detalles"
        }
      },
      {
        path: "/solicitud",
        name: "solicitud",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Dashboard/NewApplicationView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Solicitud"
        }
      },
      {
        path: "/perfil",
        name: "perfil",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/menu/MisDatosView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Perfil"
        }
      },
      {
        path: "/editar",
        name: "editar",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/menu/editarPerfilView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Editar"
        }
      },
      {
        path: "/configuracion",
        name: "configuracion",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/menu/ConfiguracionView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Configuración"
        }
      },
      {
        path: "/convenios",
        name: "Convenios",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Beneficios/ConveniosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Convenios"
        }
      },
      {
        path: "/categoria/:nombre",
        name: "Comercio",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Beneficios/ComerciosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Comercios"
        }
      },
      {
        path: "/comercio/:nombre",
        name: "Beneficio",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Beneficios/BeneficiosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Beneficio"
        }
      },
      {
        path: "/consulta",
        name: "Validar",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Validar/ValidarView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Revisar Estado"
        }
      },
      {
        path: "/convenio-lipigas",
        name: "convenioLipigas",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Validar/InscripcionBeneficioView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Inscripción Beneficio"
        }
      },
      {
        path: "/contacto",
        name: "Contacto",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Contacto/ContactoView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Contacto"
        }
      },
      {
        path: "/politicas",
        name: "Terminos",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Terminos/TerminosView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Términos y Condiciones"
        }
      },
      {
        path: "/cuenta/eliminar",
        name: "EliminarCuenta",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Web/EliminarCuentaView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/perfil/publico/:num",
        name: "PerfilPublico",
        component: () =>
          import(
            /* webpackChunkName: "encuesta" */ "@/views/Web/PerfilPublicoView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/formulario-escolar",
        name: "FormularioEscolar",
        component: () =>
          import(
            /* webpackChunkName: "formulario-escolar" */ "@/views/FormularioEscolar/FormularioView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "formulario-escolar/resultados",
        name: "Resultados",
        component: () =>
          import(
            /* webpackChunkName: "formulario-resultado" */ "@/views/FormularioEscolar/FormularioResultadoView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "formulario-escolar/finalizado",
        name: "ResultadosFinalizado",
        component: () =>
          import(
            /* webpackChunkName: "formulario-finalizado" */ "@/views/FormularioEscolar/FormularioFinalizadoView.vue"
          ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/auth",
        name: "Auth",
        component: () =>
          import(/* webpackChunkName: "Auth" */ "@/views/auth/AuthView.vue"),
        meta: {
          requiresAuth: false,
          title: "Tarjeta Vecino Ñuñoa"
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const authenticated = Store.state.authenticated;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  const notLogged = to.matched.some(record => record.meta.notLogged);

  if (requiresAuth && !authenticated) {
    next("/login");
  } else if (!requiresAuth && authenticated) {
    if (notLogged) {
      next("/vecinos");
    } else {
      next();
    }
  } else {
    next();
  }
});
const DEFAULT_TITLE = "Ñuñoa - Tarjeta Vecino";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});
export default router;
